import React, { useEffect } from "react";
import {Selections} from "./features/selectors/selectors";
import './App.css';
import { useSelector } from 'react-redux';
import { Topics } from './features/topics/topics';
import { Sidepanel } from './features/sidepanel/sidepanel.jsx'
import { Legend } from './features/legend/legend.jsx'
import { compose as C, filter as f, prop as p, propEq } from 'ramda';
import CookieConsent, {getCookieConsentValue,Cookies,} from "react-cookie-consent";
import  { Icons } from "./icons"
import * as ReactGA from "react-ga";

function App() {
  const handleAccept = () => {ReactGA.initialize('DFTMSM5662')}

  const handleDecline = () => {
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {handleAccept();}
  }, []);

  return (
    <div>
        <Icons />
        <div className={"App"}>
          <Selections/>   
          <div className={"mainpanel"}>
            <Topics data={useSelector(C(f(propEq("parent","")),p("conf")))}/>
            <Sidepanel />
            <Legend />
            <CookieConsent 
              enableDeclineButton
              onAccept={handleAccept}
              onDecline={handleDecline}
              buttonClasses="consent-btn"
              declineButtonClasses="consent-btn"
              containerClasses="consent"
              contentClasses="consent-text"
              disableStyles={true}
              debug={true}>
              Country Profiles tool uses cookies to analyze how it is being used.
            </CookieConsent>
          </div>
        </div>
    </div>
  );
}

export default App;