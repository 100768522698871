import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {inc, dec, prop as p, map as m, equals,compose as C, __, findIndex, juxt, 
    length, always, ifElse, nth, replace} from "ramda";
import styles from './itemlabels.module.css';
import { setItem } from '../../store/load_data';

export const Itemlabels = ({ item_labels, visual,is_active }) => {
    const item = useSelector(p("item"));
    const dispatch = useDispatch();
    const [prev, next] = item_labels ? C(
        m(nth(__, item_labels)),
        juxt([
            ifElse(equals(dec(length(item_labels))), always(0), inc),
            ifElse(equals(0), always(dec(length(item_labels))), dec)
        ]),
        findIndex(equals(item)),
    )(item_labels) : [];
    const shorten = [["All students: ", ""], ["national currency adjusted to", ""]];
    return <div onClick={x => {if(is_active) x.stopPropagation()}} className={is_active ? styles.active : ""}>
        <div className={styles.hover_title}>{item ? C(...m(x => replace(...x), shorten))(item) : ""}</div>
        {length(item_labels) > 1 ? <>
            <div className={styles.next_item} onClick={x => {if(is_active) {x.stopPropagation()} dispatch(setItem(next)); }}></div>
            <div className={styles.prev_item} onClick={x => {if(is_active) {x.stopPropagation()} dispatch(setItem(prev)); }}></div>
        </> : ""}
    </div>;
};

