import {filter as f,map as m,compose as C,converge,identity,sum,concat,mergeDeepWith, 
    reduce, length, values, curry, contains, divide, of, pick, reject, __, uniq, append} from 'ramda'; 
import {r,abs} from "./math"

const rounded_percents = curry((sum,list) => m(x => r(3,x/sum),list));
const percents = m(converge(rounded_percents,[sum,identity]))
const omit_empty = reject(contains(NaN));
const prepare = selected => C(
    omit_empty, 
    percents,
    reduce(mergeDeepWith(concat),{}),
    m(C(m(of),pick(selected))))

const array_of_sums = selected =>  C(
    reduce((acc,xs) => xs.map((x,n) => x+(acc[n] || 0)),[]),
    values,
    pick(selected))
const count = selected => C(length,values,pick(selected))
const averages = (len,xs) => m(divide(__,len),xs);
const diff = avgs => xs => xs.map((x,n) => x-avgs[n]);
const diffs = fn => (selected,country,data) => C(
    fn,
    converge(m,[converge(C(diff,averages),[count(selected),array_of_sums(selected)]),identity]),
    prepare(uniq(append(country,selected))))(data)

const round_summed_abs = m(C(r(2),sum,m(abs)));
const round_values  = m(m(r(2)));

export const summed_diffs = diffs(round_summed_abs);
export const just_diffs = diffs(round_values);