import { useSelector } from 'react-redux';
import React from 'react';
import styles from './indicator.module.css';
import select from './sidepanel.selectors.js';
import { take_from } from './subindicator/subindicator';

export const Indicator = ({ bubble }) => {
    const explanation = useSelector(select.explanation);
    const formula_explain = useSelector(take_from("explanations",bubble.id));
    return <div className={styles.indicator}>
        <h2 className={styles.title}>{bubble.Name}</h2>
        <p className={styles.explanation}>{bubble.fn === "avg" ? formula_explain : explanation}</p>
    </div>;
};
