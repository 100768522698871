import React from 'react';
import {map as m, compose as C,omit, toPairs, prop as p, __, head, last, reduce,
    both, includes, lte, gte} from "ramda";
import styles from './means.module.css';
import { normalize, r } from '../../../store/transforms/math';

export const Means = ({ data, country, cls }) => {
    const between = (low,high,value) => both(gte(__,low),lte(__,high))(value);
    const shift_extremes = x => between(0,10,x) ? (10-x)*40 : between(90,100,x) ? (100-x)*-40 : -300;
    const shifts = (name,top) => includes(name,["max",""]) ? {} : {color:"#2C70D4",top:`${shift_extremes(top)}%`};
    const lines = m(
        C(r(2), x => 100 - (x * 100)),
        normalize(omit(["i_item", "indicator", "topic"], data),true));
    const value_lines = [
        [0,100,""],
        [C(r(2),p(__,data),head,reduce((a,b) => last(b) < last(a) ? b : a,["",100]),toPairs)(lines),0,"max"],
        [data[country],lines[country],"current"]]
    return <div className={`${styles.lines} ${cls.active ? styles.active : ""}`}>
        {m(([key, val]) => <div key={key} style={{ top: `${val}%` }}
            className={`${styles.line} ${key === country ? styles.country_line : ""}`}>
        </div>, toPairs(lines))}
        {m(([value,top,name]) => 
            <div className={styles.value_line} key={name}
                style={{
                    top:`${top}%`,
                    background:`${includes(name,["max",""]) ? "#c3dafd" : "#2C70D4"}`,
                    height: `${includes(name,["max",""]) ? "0.1" : "0.2"}rem`}}>
                <span className={styles.name} style={shifts(name,top)}>{name}</span>
                <span className={styles.value} style={shifts(name,top)}>{r(1,value)}</span>
            </div>,
        value_lines)}
    </div>;
};
