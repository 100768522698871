import React from 'react';
import {always, converge, defaultTo, divide, equals, findIndex, fromPairs, head,
    identity, ifElse, inc, keys, last, length, map as m,max,min,pair,prop as p, 
    reverse, sortBy, splitEvery, sum, take, tap, toPairs, transpose, __} from "ramda";
import styles from './top5.module.css';
import { useSelector } from 'react-redux';
import { compose as C } from '@reduxjs/toolkit';
import { abs, r } from '../../../store/transforms/math';

export const Top5 = ({ data, country,cls }) => {
    const countrynames = useSelector(p("countrynames"));
    const colors = useSelector(p("colors"));
    const take_rank = x => C(
        inc,
        findIndex(C(equals(head(x)),Number,head)),
        reverse,
        sortBy(last),
        toPairs,last
    )(data);
    const max_rank = length(keys(last(data)));
    const typical_color = C(
        xs => xs.reduce((acc,x,n) => ({...acc,...C(fromPairs,m(pair(__,colors[n])))(x)}),{}),
        converge(splitEvery,[C(max(1),tap(console.log),Math.ceil,divide(__,5),length),identity]),
        m(head),
        reverse,
        sortBy(last),
        toPairs,last
    )(data);
    const take_name = ifElse(
        C(equals(1),length),
        always("Other"),
        C(defaultTo("Other"),p(__,countrynames),head));
    const take_value = ifElse(C(equals(1),length),identity,last);
    const items = C(
        take(5),
        xs => xs.map((x,n,xs) => ({...x,bar_middle: x.bar_top+(x.bar_height/2)})),
        xs => xs.map((x,n,xs) => ({
            id: x,
            color: typical_color[Number(head(x))],
            bar_height: take_value(x)*0.8,
            bar_top: xs.slice(0,n).reduce((acc,x) => sum([acc,3,Number(last(x))]),0),
            text_top: (n/(xs.length-1)*160),
            text_middle: (n/(xs.length-1)*90)+8})),
        m(m(p(country))),
        transpose,
        head)(data)
    const background = x => `linear-gradient(to top ${x ? "right" : "left"}, 
        transparent calc(50% - 0.1rem), #2C70D4, transparent calc(50% + 0.1rem))`
    return <div className={`${styles.top5} ${cls.active ? styles.opened : ""}`}>
        {items.map((x,n,xs) => <div className={styles.item}>
            <div className={styles.percent} style={{top: `${x.text_top}%`}}>
                <div>{`${r(0,x.bar_height)}%`}</div>
            </div>
            <div className={styles.connecting_line_left} style={{
                background: background(x.text_middle<x.bar_middle),
                height: `${r(0,abs((x.bar_middle)-x.text_middle))}%`,
                top: `${r(0,min(x.bar_middle,x.text_middle))}%`,
            }}></div>
            <div className={styles.bar} style={{ 
                background: x.color,
                height: `${r(0,x.bar_height)}%`,
                top: `${r(0,x.bar_top)}%`}}></div>
            <div className={styles.connecting_line_right} style={{
                background: background(x.text_middle>x.bar_middle),
                height: `${r(0,abs((x.bar_middle)-x.text_middle))}%`,
                top: `${r(0,min(x.bar_middle,x.text_middle))}%`,
            }}></div>
            <div className={styles.item_text}>
                <div className={styles.rank}>{`${take_rank(x.id)}#`}</div>  
                <div className={styles.max_rank}>{` / ${max_rank}`}</div>
                <div className={styles.country_name}>{take_name(x.id)}</div>
            </div>
        </div>)}
    </div>;
};
