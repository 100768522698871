import React, { useState } from 'react';
import styles from './legend.module.css';
import { useSelector } from 'react-redux';
import { compose as C, keys, map as m, prop as p, reverse } from 'ramda';
import QRCode from 'react-qr-code';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const steps = [
    {message: "The aim of the country profiles application is to serve as a data discovery tool for the Eurostudent database. The tool is not supposed to evaluate how good the country is doing in comparison, but merely highlight differences and link it easily to a database link."},
    {message: "Select a country you wish to compare."},
    {message: "Select countries that you want to compare your selected country to.", nb:"Some indicators measure difference from comparison countries in standard deviations. When choosing only one comparison country, standard deviation becomes 0 (single country's value doesn't deviate from itself) and therefore the difference cannot be calculated. We reccommend choosing at least 2 comparison countries."},
    {message: "The application calculates the difference on a 0.00-1.00 scale,which is divided into 5 equal parts (5 levels of difference). The legend can be opened by clicking on the left edge of the screen and closed by clicking on the legend."},
    {message: "To see more information about the indicator, click on the bubble."},
    {message: "Some indicators consist of subindicators. An illustrative graph of difference form comparison is shown for each subindicator that the country has."},
    {message: "To see more information about the currently opened subindicator, click on the buttons below the visual. The last button opens the indicator in database, or in some cases shows all the links in information pane, when current indicator is based on more than 1 database links."}]

export const Legend = () => {
    const [opened,toggle] = useState(false);
    const [colors,meanings,country,comparison,countries] = m(C(useSelector,p),["colors","color_meaning","country","comparison","countries"]);
    const compare = comparison.length !== keys(countries).length ? `&comparison=${comparison.join(",")}` :"";
    return <>
        <div className={`${styles.legend} ${opened ? styles.opened : ""}`} 
            onClick={x => toggle(!opened)}>
            {reverse(colors).map((x,n) => <div key={`legend${n}`} className={styles.color} style={{borderColor:x}}>
                <span>{reverse(meanings)[n]}</span>
            </div>)}
        </div>
        <div className={styles.button} onClick={x => toggle(!opened)}>
            <svg xmlns="http://www.w3.org/2000/svg" >
                <use xlinkHref={"#info"}/>
            </svg>
        </div>
        <h2 className={styles.qrtext} >For more information, use the online tool</h2>
        <QRCode value={`https://countryprofiles.eurostudent.eu?country=${country}${compare}`} 
            className={styles.qr} fgColor="#2C70D4" size={120}/>
        {/* <Carousel 
            showStatus={false}
            className={styles.tutorial}
            animationHandler="fade"
            renderIndicator={(onClickHandler, isSelected, index, label) => {
                if (isSelected) {
                    return (
                        <li
                            className={`${styles.dot} ${styles.dot_selected}`}
                            aria-label={`Selected: ${label} ${index + 1}`}
                            title={`Selected: ${label} ${index + 1}`}
                        />
                    );
                }
                return (
                    <li
                        className={styles.dot}
                        onClick={onClickHandler}
                        onKeyDown={onClickHandler}
                        value={index}
                        key={index}
                        role="button"
                        tabIndex={0}
                        title={`${label} ${index + 1}`}
                        aria-label={`${label} ${index + 1}`}
                    />
                );
            }}>
            {steps.map((x,n) => <div className={styles.tutorial_slide} key={"slide"+n}>{x.message}</div>)}
        </Carousel> */}
    </>
}