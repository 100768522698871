import { createSelector } from "@reduxjs/toolkit";
import {compose as C, countBy, defaultTo, find,gt,head,
    identity,ifElse, isNil, juxt, length, map as m, max, nth, of, 
    prop as p, propEq, props, reduce, unless, values, __ } from "ramda";
import { calculate } from "../../store/transforms/calculations";

const bubble = createSelector(
    m(p,["bubble","conf"]),(id,conf) => find(propEq("id",id),conf));

const formula = createSelector(m(p,["bubble","conf","explanations","fns"]),
    (id,conf,explanations,fns) => C(
        p(__,m(C(p(__,explanations),p("explain")),fns)),
        p("fn"),
        find(propEq("id",id))
    )(conf));

const explanation = createSelector(m(p,["bubble","labels","conf"]),
    (id,labels,conf) => C(
        defaultTo(""),
        p(__,labels),
        p("id"),
        find(propEq("id",id))
    )(conf));

const if_same_take_labels = unless(isNil,
    ifElse(C(
        gt(2),reduce(max,0),values,countBy(identity),nth(1))
        ,nth(1),head));

const subindicators = createSelector(
    m(p,["bubble","conf","data","comparison","item_labels","fns","country"]),
    (id,conf,data,sel,item_labels,fns,country) => C(
        m(C(
            juxt([
                calculate(data,country,sel,fns,"visual_data"),
                props(["key","fn","Name","id"]),
                C(if_same_take_labels,p(__,item_labels),Number,p("id")),
                x => m(p("visual"),fns)]),
            defaultTo({}),
            x => find(propEq("id",x),conf))),
        ifElse(C(length,p("children")),p("children"),C(of,p("id"))),
        defaultTo({}),
        find(propEq("id",id)),
    )(conf))

const exports = {bubble,explanation,subindicators,formula};
export default exports;