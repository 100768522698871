import {createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { calculate_differences } from './transforms/calculations';
import axios from "axios";
import { apply, compose as C, concat, countBy, find, forEach,fromPairs, 
    gt, head, identity, ifElse, isNil, join, juxt, keys, last, length, 
    lt, map as m, max, nth, objOf, of, pair, path, prop as p, propEq, reduce, 
    reject, split, toPairs, unless, values, __ } from 'ramda';
import { initial } from './initial';

const fetch_data = file => new Promise((resolve) => axios.get(file).then(resolve));
const set = key => (state,{payload}) => {state[key] = payload}

export const slice = createSlice({
    name: "main",
    initialState: initial,
    reducers: {
        loadState: (state, {payload}) => 
            ({...state,...payload,comparison: keys(payload.countries)}),
        toggle_panel: (state) => {state.panel_open = !state.panel_open},
        selectCountry: set("country"),
        selectComparison: set("comparison"),
        setDifferences: set("differences"),
        setIndicator: set("bubble"),
        setSubindicator: set("select"),
        setActiveLabels: set("active_labels"),
        setItem: set("item"),
        setPane: set("pane"),
        setTutorial: set("tutorial"),
        setUrl: set("url")}});

export const {setIndicator,setSubindicator,setItem,selectCountry,
    selectComparison,setDifferences: calcDiffs,loadState,
    toggle_panel,setActiveLabels,setPane,setTutorial,setUrl} = slice.actions; 
    
const set_url = (val) => (dispatch,getState) => {
    const {url} = getState();
    dispatch(setUrl({...url,...val}))
    const params = C(concat("?"),join("&"),m(join("=")),toPairs,reject(isNil))({...url,...val})
    window.history.replaceState(null,null,params); 
}
export function recalc_data(selected) {
    return (dispatch,getState) => {
        const {country,data,conf,fns,response_rates} = getState();
        dispatch(selectComparison(selected));
        dispatch(calcDiffs(calculate_differences(selected,country,data,conf,fns,response_rates)))
        dispatch(set_url({"comparison": selected || null}));};
}
export function update_country(new_country) {return (dispatch,getState) => {
        const {comparison,data,conf,fns,response_rates} = getState();
        dispatch(selectCountry(new_country));
        dispatch(calcDiffs(calculate_differences(comparison,new_country,data,conf,fns,response_rates)));
        dispatch(set_url({"country": new_country}));};
}
const multi = (conf,bubble) => C(gt(__,1),length,p("children"),find(propEq("id",bubble)))(conf);

export const setLabels = (item) => (dispatch,getState) => {
    const state = getState();
    const id = item ? item : state.bubble;
    const nth_item = C(
        ifElse(isNil,() => head,C(
            ifElse(C(lt(__,2),length),() => head,C(nth,nth(1))),
            split(","))),
        p("visual_data"),
        p(__,state.fns),
        p("fn"),
        find(propEq("id",id)),
        p("conf"))(state)  
    const active_labels = C(
        unless(isNil,ifElse(C(lt(__,2),reduce(max,0),values,countBy(identity),last),last,head)),
        path(["item_labels",Number(id)]))(state);
    dispatch(setActiveLabels(active_labels));
    dispatch(setItem(!active_labels || multi(state.conf,Number(id)) ? null : nth_item(active_labels)));
}
export const selectSubindicator = ({select,select2,item}) => (dispatch) => {
    dispatch(setSubindicator(select));
    if(select2) {setTimeout(() => {dispatch(setSubindicator(select2 || {active:null}));},300);}
    dispatch(setLabels(item));
    dispatch(set_url({"subindicator": item || null}));
}
export const toggleTutorial = (val) => (dispatch) => {
    window.sessionStorage.setItem('tutorial', val);
    dispatch(setTutorial(!!val));
}
export const selectIndicator = id => (dispatch,getState) => {
    const state = getState();
    const conf = find(propEq("id",id),state.conf)
    const multiple = multi(state.conf,id);
    dispatch(toggle_panel());
    dispatch(setIndicator(id));
    if(state.tutorial === null && state.panel_open === false) dispatch(toggleTutorial(true))
    dispatch(setSubindicator({active:multiple ? null : conf.Name}));
    if (multiple) dispatch(setPane(0));
    dispatch(setLabels());
    dispatch(set_url({indicator:id, subindicator:null}));
}

const load_url = () => (dispatch,getState) =>{
    const url = new URLSearchParams(window.location.search.slice(1));
    const {conf} = getState();
    const sub_fn = C(of,fromPairs,juxt([
        C(pair("select"),objOf("active"),p("Name"),x => find(propEq("id",x),conf)),
        pair("item")]));
    const params = [
        ["comparison",recalc_data,C(of,split(","))],
        ["country",update_country,of],
        ["indicator",selectIndicator,of],
        ["subindicator",selectSubindicator,sub_fn]]
    forEach(([x,fn,prep]) => {const val = url.get(x);if(val) dispatch(apply(fn,prep(val)))},params);
}

export const initialize_state = createAsyncThunk("load_data",async (_,{dispatch,getState}) => {
    const response = await fetch_data("state.json");
    const sel = keys(response.data.countries);
    dispatch(loadState(response.data));
    const {data,conf,fns,country,response_rates} = getState();
    dispatch(calcDiffs(calculate_differences(sel,country,data,conf,fns,response_rates)));
    dispatch(setTutorial(JSON.parse(window.sessionStorage.getItem('tutorial'))));
    dispatch(load_url());  
});