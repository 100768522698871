import React from 'react';
import { compose as C, equals, join, prop as p, reject, split, toUpper } from "ramda";
import styles from './subindicator.module.css';


const url = "https://database.eurostudent.eu/drm2020j/?fg=all_students";

export const eust_link = ({ key, countries = [], unit = "NCU", ver = 7 }) => 
    `${url}&e=${key}&Curr=${toUpper(unit)}&eust_nr=${ver}&country_list=${join(",", countries)}`;
    
export const Links = ({countries,bubble}) => {
    return <>
    {(C(reject(equals("")),split(","),p("key"))(bubble))
        .map((x,n) => <a key={`${x.key}${n}`} target="_blank" rel="noreferrer" className={styles.link} 
        href={eust_link({...bubble,key:x,countries})}>
        link {n+1}
        <svg xmlns="http://www.w3.org/2000/svg">
            <use xlinkHref="#link" />
        </svg>
    </a>)}
</>;}