export const initial = {
    comparison: [],
    countries: {},
    country: undefined,
    bubble: "12",
    select: {active:"Age",fade_out:null,fade_in:null},
    active_labels: [
        '< 22 years',
        '22 - 24 years',
        '25 - 29 years',
        '30 years and older'
      ],
    item: "< 22 years",
    conf: [],
    status: 'idle',
    url: {},
    differences: {},
    panel_open: false,
    data: {},
    tutorial: true,
    pane:0,
    item_labels: {},
    labels: {},
    samples: {},
    fns: {},
    explanations: {},
    colors: []
};
