import { useDispatch, useSelector } from 'react-redux';
import React, { createElement as el} from 'react';
import {inc, dec, prop as p, map as m, equals, filter as f, join, compose as C, 
    keys, __, length, ifElse, defaultTo, find, propEq, of, reject, split} from "ramda";
import styles from './subindicators.module.css';
import { Btns, Panes } from './subindicator/subindicator';
import { selectSubindicator } from '../../store/load_data';
import { createSelector } from '@reduxjs/toolkit';

const Roundbutton = ({current,to,cls}) => {
    const dispatch = useDispatch();
    return <div className={`${styles.round_btn} ${styles[cls]}`} 
        onClick={() => dispatch(selectSubindicator(
            {select:{active:current, fade_out: current,fade_in:to.Name},
            select2:{active:to.Name},
            item:to.id
            }))}>
        <svg xmlns="http://www.w3.org/2000/svg" style={{
            transform: `rotate(${cls === "next" ? 90 : -90}deg)`}} >
            <use xlinkHref={"#triangle"}/>
        </svg>
    </div>
}
const TitleChange = ({name,prev,next}) => {
    return <>
        <Roundbutton current={name} to={prev} cls={"prev"}/>
            <div className={styles.indicator_title}>
            <h4>{name}</h4>
        </div>
        <Roundbutton current={name} to={next} cls={"next"}/>
    </>}
const CloseBtn = () => {
    const dispatch = useDispatch();
    return <div className={`${styles.round_btn} ${styles.close}`} 
        onClick={() => {dispatch(selectSubindicator({select:{active:null}}))}}></div>}

const Subindicator = ({current,prev,next,square,multi}) => {
    const name = p("Name",current);
    const cls = multi ? f(equals(name),square) : {active: name};
    const links = (C(reject(equals("")),split(","),p("key"))(current))
    return <div className={`${styles.ind_container} ${multi ? "" : styles.single}
        ${join(" ",m(p(__,styles),keys(cls)))}`}>
        {multi ? <TitleChange {...{prev,next,name}}/> : ""}
        <Panes {...{current,multi,cls,carousel:square.active,links}}/>
        <Btns {...{links,current,cls,carousel:square.active}} />
        {multi ? <CloseBtn></CloseBtn> : ""}
    </div>;
};
export const Subindicators = () => {
    const subindicators = useSelector(createSelector(m(p,["bubble","conf"]),
        (id,conf) => C(
            m(C(defaultTo({}),find(__,conf),propEq("id"))),
            ifElse(C(length,p("children")),p("children"),C(of,p("id"))),
            defaultTo({}),
            find(propEq("id",id)),
        )(conf)));
    const square = useSelector(p("select"));
    return <div className={`${styles.subindicators} ${square.active !== null ? styles.carousel : ""}`}>
        {subindicators.map((current,n,xs) => {
            const prev = p(n === 0 ? xs.length-1 : dec(n),xs);
            const next =  p(xs.length-1 === n ? 0 : inc(n),xs);
            const multi = next.id !== p("id",current);
            return el(Subindicator, {current,prev,next,square,multi,key:current.id})
        })}
    </div>;
};

