import { useSelector } from 'react-redux';
import React, { createElement as el } from 'react';
import { compose as C, equals, isNil, join, keys, map as m, path, prop as p, reject, __ } from "ramda";
import { Means } from './means.jsx';
import { Columns } from './columns.jsx';
import { Share,Share2 } from './share.jsx';
import { Top5 } from './top5.jsx';
import { Missing } from './missing.jsx';
import styles from './graph.module.css';
import { createSelector } from '@reduxjs/toolkit';

const graph_types = { Means, Columns, Top5, Share, Share2 }

export const Graph = ({current,visual,item_labels,data,cls,multi}) => {
    const country = useSelector(p("country"));
    const responses0 = useSelector(createSelector(m(p,["response_rates","country"]),
    (rates, country) => equals(0,path([Number(current.id),country],rates))));
    const missing_data = visual === "Top5" ? false : isNil(data[country]);
    // console.log({missing_data,responses0,visual,missing:(responses0 || missing_data)});
    return <div className={`${styles.indicator_visual} ${C(join(" "),m(p(__,styles)),keys,reject(isNil))(cls)}`}>
        {el((responses0 || missing_data) ? Missing : p(visual, graph_types),{data,country,item_labels,cls})}
    </div>;
};
