import React, { useState } from 'react';
import styles from './select.module.css';
import {compose as C, filter as f, head, identity,includes, keys, lensProp,
  map as m, mapObjIndexed, not,over, toPairs} from "ramda";
import { useDispatch } from 'react-redux';
import { recalc_data, update_country } from '../../store/load_data';

const Overview = ({ onClick, text, style }) => 
  <h2 onClick={onClick}>{text} 
    <svg xmlns="http://www.w3.org/2000/svg" style={{
      ...style,
      marginTop: ".2rem",
      marginLeft: "2.0rem",
      verticalAlign: "middle",
      transform: "rotate(180deg)",
      transformOrigin: "center"
    }} >
        <use xlinkHref={"#triangle"}/>
    </svg>
  </h2>;

const Button = ({ data, name }) => {
  const dispatch = useDispatch();
  return <div className={styles.button} onClick={x => dispatch(recalc_data(data))}>{name}</div>;};

const Options = ({ click_fn, options, name_map, name, multiple, country }) => {
  return <div className={styles.options}>
    {multiple ? <Button data={m(head, options)} name="ALL"></Button> : ""}
    {multiple ? <Button data={[]} name="NONE"></Button> : ""}
    {m(([code, selected]) => <div key={`${name} ${code}`} value={code}
      className={styles[selected ? "selected" : "unselected"]}
      onClick={click_fn(code)}>{name_map[code]}
    </div>, options)}
  </div>};

export const Select = ({ name, options, multiple, format, cls, selected, style, country }) => {
  const dispatch = useDispatch();
  const [opened, setOpen] = useState(false);
  const option_map = C(mapObjIndexed((val, key) => includes(key, selected)))(options);
  const all_selected = x => C(keys, f(identity), over(lensProp(x), not))(option_map);
  
  return <div name={name} tabIndex={0} onBlur={x => setOpen(false)} 
        className={`${styles.selection} ${opened ? styles.opened : ""} ${cls}`}>
    <Overview selection={selected} text={format(selected)} style={style}
      onClick={x => setOpen(!opened)}></Overview>
    <Options selection={selected} options={toPairs(option_map)} name={name} multiple={multiple} 
      name_map={options} country={country}
      click_fn={code => x => {
        if (!multiple)
          setOpen(false);
          dispatch(multiple ? recalc_data(all_selected(code)) : update_country(code));}}/>
  </div>;
};