import {compose as C, map as m, values,mean,subtract, pick, head, defaultTo, nth, append, uniq} from "ramda";
import {r,abs,sqrt} from "./math";
const ztable = require('./z_scores.json');

export const diff_from_mean = item_nr => (selected,country,data) => {
    const nth_item = C(defaultTo({}),nth(item_nr))(data)
    const selected_data = pick(selected,nth_item);
    const Mean = mean(m(Number,values(selected_data)))
    const std = C(sqrt,mean,values,m(C(x => x*x,subtract(Mean))))(selected_data)
    const normal = x => ztable[r(2,abs(x))];
    return C(
        m(x => normal((Number(x)-Mean)/std) * 2),
        pick(uniq(append(country,selected)))
    )(nth_item)
}

export const take_mean = (sel,country,data) => pick(uniq(append(country,sel)),head(data));