import {share} from "./share";
import {summed_diffs,just_diffs} from "./dist";
import {diff_from_mean, take_mean} from "./mean";
import {normalized_typical, typical_with_scores} from "./top5";
import {apply, compose as C, concat, converge, curry, defaultTo, fromPairs, 
    filter as f, groupBy, head, identity, ifElse, includes, isNil, juxt, last, 
    map as m, mean, mergeDeepWith, not, of, prop as p, propEq, reduce, reject, 
    split, test, __, tap, keys, omit} from 'ramda';
import {r} from "./math"

const fns = {just_diffs,identity,take_mean,share,typical_with_scores,
    summed_diffs,diff_from_mean,normalized_typical}
const propAmong = curry((key,arr) => C(includes(__,arr),p(key)));
const take_children = C(propAmong("parent"),m(p("id")),f(propEq("fn", "avg")));
const take_fn = (fn_map,fn_key) => C(
    defaultTo(identity),
    p(__,reject(
        isNil,
        m(C(
            ifElse(test(/,/),
                C(converge(apply,[C(p(__,fns),head),C(of,last)]),split(",")),
                p(__,fns)),
            p(fn_key)),
            fn_map))),
    p("fn"));
const means_of = differences => C(
    m(C(r(2),mean)),
    tap(console.log),
    reduce(mergeDeepWith(concat),{}),
    tap(console.log),
    m(C(m(of),p(__,differences),p("id"))))

export const take_args = (data,country,sel) => C(concat([sel,country]),of,C(p(__,data),p("id")));
export const calculate = (data,country,sel,fn_map,fn_key) => 
    converge(apply,[
        take_fn(fn_map,fn_key),
        take_args(data,country,sel)]);
export const calculate_differences = (selected,country,data,conf,fn_map,response_rates) => {
    const no_responses = m(C(keys,f(x => !x)))(response_rates)
    const differences = C(
        fromPairs,
        m(C(
            ([key,values]) => [key,omit(no_responses[key],values)],
            juxt([p("id"),calculate(data,country,selected,fn_map,"diff")]))),
        f(C(not,propAmong("fn",["","avg"]))))(conf);

    console.log(differences);

    const averages = C(
        m(means_of(differences)),
        groupBy(p("parent")),
        converge(f,[take_children,identity]),
    )(conf)


    return {...differences,...averages} 
}