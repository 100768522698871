import React from 'react';
import {map as m, compose as C,omit, toPairs, multiply, prop as p, reverse,  nth} from "ramda";
import styles from './share.module.css';
import { r } from '../../../store/transforms/math';
import { useSelector } from 'react-redux';
import { setItem } from '../../../store/load_data';
import { useDispatch } from 'react-redux';

const Shares = ({data,country,labels,cls}) => {
    const item = useSelector(p("item"));
    const dispatch = useDispatch();
    const lines = m(
        C(r(2),multiply(100)),
        omit(["i_item", "indicator", "topic"], data));
    const breakpoint = nth(country,lines);
    return <div className={styles.lines}>
        {m(([key, val]) => <div key={key} style={{ left: `${val}%` }}
            className={`${styles.line} ${key === country ? styles.country_line : ""}`}>
        </div>, toPairs(lines))}
        {labels ? <>
            <div style={{ width: `${breakpoint}%` }}
                className={`${styles.areas} ${item === labels[0] ? styles.active : ""}`}
                onClick={x => {if(cls && cls.active) {x.stopPropagation()}; dispatch(setItem(labels[0]))}}>
                <span>{breakpoint}%</span>
            </div>
            <div style={{ left: `${breakpoint}%`, width:`${100-breakpoint}%` }}
                className={`${styles.areas} ${item === labels[1] ? styles.active : ""}`}
                onClick={x => {if(cls && cls.active) {x.stopPropagation()}; dispatch(setItem(labels[1]))}}>
                <span>{100-breakpoint}%</span>
            </div>
        </> : ""}
    </div>;}

export const Share = ({ data, country, item_labels,cls}) => {
    return <Shares labels={item_labels} data={data} country={country} cls={cls} />
}
export const Share2 = ({ data, country, item_labels,cls }) => {
    const rev = x => x ? reverse(x) : [];
    return <Shares labels={rev(item_labels)} data={data} country={country} cls={cls} />
}

