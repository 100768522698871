import { compose as C, filter as f, prop as p, propEq } from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { Bubble } from './bubble';
import styles from "./topic.module.css";
import bubble_styles from "./bubble.module.css";

const Title = ({text}) => <h3 className={styles.title}>{text}</h3>;

export function Topic({topic,id,expanded,click}) {
    const bubbles = useSelector(C(f(propEq("parent",id)),p("conf")));
    return <div onClick={click} className={expanded ? bubble_styles.expanded : styles.topic}>
        <Title text={topic} />
        {bubbles.map((x => <Bubble key={x.id} id={x.id} icon={x.icon} name={x.Name}/>))}
    </div>
}