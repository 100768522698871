import React from 'react';
import styles from './selectors.module.css';
import {compose as C, concat, gte, join, length, map as m, 
  of, prop as p, splitAt, sum, when, __} from "ramda";
import { useSelector } from 'react-redux';
import { Select } from './select';

const compared_to_3_or_more = names => xs => {
  const [first3,rest] = splitAt(2,xs);
  const shortened = C(
    concat(__,rest.length ? ` + ${rest.length}` : ""),
    join(", "),
    when(C(gte(10),sum,m(C(length,p(__,names)))),m(p(__,names))))(first3)
  const full = join(", ", xs)
  return xs.length === 0 ? "select comparison" : 
    <span>compared to 
      <span className={`${styles.shortened} ${xs.length >= 10 ? styles.full : ""}`}> {shortened}</span> 
      {xs.length < 10 ? <span className={styles.full}> {full}</span> : ""}
    </span>}
      
export const Selections = () => {
  const options = useSelector(p("countries"));
  const comparison = useSelector(p("comparison"));
  const country = useSelector(p("country"));
  return (
    <div className={styles.selection_panel}>
      <Select 
        name="Country"
        format={([x]) => `${options[x] || "Select Country..."}`}
        style={{fill: "#2C70D4",stroke: "none",height: "3.0rem",width: "3.0rem"}}
        selected={of(country)}
        options={options}
        cls={styles.country}/>
      <Select 
        name="Countries"
        format={compared_to_3_or_more(options)}
        style={{fill: "transparent",stroke: "#2C70D4",height: "2.0rem",width: "2.0rem"}}
        options={options}
        country={country}
        selected={comparison}
        cls={styles.comparison}
        multiple={true} />
    </div>)
} 