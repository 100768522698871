import {reduce, chain,compose as C,map as m,prop as p, filter as f,not as n, 
    groupBy as g,transpose, values, includes, divide,sum,multiply,apply,zipWith,
    juxt,head,nth, pick,toPairs,mergeDeepWith,concat,of,slice, pair, __,} from "ramda";
import {normalize, r} from "./math";

export const destination_avg_scores = (selected,arrays) => C(
    reduce((acc,[key,val]) => ({...acc,[key]:(acc[key] || 0) + val}),{}),
    chain(C(
      transpose,
      m(C(m(Number),values)))),
      transpose,
      m(C(
        m(pick(selected)),
        f(C(
          n,
          includes(__,[6,"toprest","nu","n"]),
          p("i_item"))),
      ))
  )(arrays)

const typicalness_score = (selected,scores,data) => C(
  m(C(
    divide(__,selected.length),
    sum,
    apply(zipWith(multiply)),
    m(C(
      juxt([C(p(__,scores),head),nth(1)]),
      m(Number),
      nth(1))))),
  pick(selected),
  g(head),
  chain(C(
    toPairs,
    reduce(mergeDeepWith(concat),{}),
    m(m(of)))),
  transpose,
  m(slice(0,5))
)(data)

export const normalized_typical = (selected,country,data) => {
    const dest_scores  = destination_avg_scores(selected.concat([country]),data);
    return C(m(x => r(2,1-x)),normalize)(typicalness_score(selected.concat([country]),dest_scores,data));
}

export const typical_with_scores = (selected,country,data) => {
  const dest_scores  = destination_avg_scores(selected.concat([country]),data);
  return pair(data,normalize(dest_scores));
}