import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {prop as p, map as m} from "ramda";
import styles from './sidepanel.module.css'
import select from './sidepanel.selectors.js';
import { Bubble } from '../topics/bubble';
import { Indicator } from './indicator.jsx';
import { Subindicators } from './subindicators.jsx';
import { toggleTutorial } from '../../store/load_data';

export const Sidepanel = () => {
    const tutorial = useSelector(p("tutorial"));
    const dispatch = useDispatch();
    const [bubble,opened] = m(useSelector,[select.bubble,p("panel_open")]);
    return bubble ? (<div className={`${styles.sidepanel} ${opened ? styles.opened : ""}`}>
        <Bubble {...bubble} style={`${styles.bubble} ${tutorial ? styles.pulse_bubble : false}`} name={null} />
        <Indicator bubble={bubble}/>
        <Subindicators bubble={bubble}/>
        <div className={`${styles.close_notify} ${!tutorial ? styles.hide : ""}`}>
            <p>To close sidepanel click on bubble in upper right corner.
            </p>
            <div>
                <button onClick={x => {dispatch(toggleTutorial(false))}}>Got it</button>
                <button onClick={x => {dispatch(toggleTutorial(null))}}>Remind me again</button>
            </div>
        </div>
    </div>) : "";}
