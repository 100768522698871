import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import {prop as p, map as m, compose as C, path, mean, props, __, find, propEq, isNil, clamp} from "ramda";
import styles from './subindicator.module.css';
import { createSelector } from '@reduxjs/toolkit';
import { r } from '../../../store/transforms/math';
import { calculate } from '../../../store/transforms/calculations';
import { Itemlabels } from '../itemlabels';
import { Graph } from '../graphs/graph';
import { selectSubindicator, setPane } from '../../../store/load_data';
import { eust_link, Links } from './links';

export const take_from = (x,id) => createSelector(m(p,[x,"conf","fns"]),
    (obj,conf,fns) => C(p(__,obj),p("explain"),p(__,fns),p("fn"),find(propEq("id",id))
)(conf));

const Btn = ({children,n}) => {
    const pane_nr = useSelector(p("pane"));
    const dispatch = useDispatch();
    return <div 
        className={`${styles.btn} ${n === pane_nr ? styles.active_btn : ""}`} 
        onClick={() => {dispatch(setPane(n))}}>
            {children}
        </div>}

const LinkBtn = ({links,current}) => {
    const dispatch = useDispatch();
    const countries = useSelector(p("comparison"));
    return links.length === 1 ? 
        <a target="_blank" rel="noreferrer" href={eust_link({...current,key:links[0],countries})}>
            <div className={styles.btn}>
                <Svg refId={"#link"}></Svg>
            </div>
        </a> 
        : 
        <div className={styles.btn} onClick={() => {dispatch(setPane(1))}}>
            <Svg refId={"#link"}></Svg>
        </div>        
    }

const Svg = ({refId}) => 
    <svg xmlns="http://www.w3.org/2000/svg"><use xlinkHref={refId}/></svg>
        
export const Btns = ({links,current,cls,carousel}) => 
    <div className={`${styles.buttons} 
    ${carousel !== null ? styles.carousel : ""}
    ${cls.active !== null ? styles.active : ""}`}>
        <Btn key={"graph"} n={0} ><Svg refId={"#graph"}></Svg></Btn>
        <Btn key={"info"} n={1} ><Svg refId={"#info"}></Svg></Btn>
        <Btn key={"respondent"} n={2}><Svg refId={"#percents"}></Svg></Btn>
        <Btn key={"formula"} n={3}><Svg refId={"#formula2"}></Svg></Btn>
        <LinkBtn {...{current,links}}></LinkBtn>
    </div> 

const NilNaN = x => isNil(x) || isNaN(x);
const colour = colors => x => NilNaN(x) ? "white" : colors[clamp(0,4,Math.floor(x*5))];

const Pane = ({children}) => <div className={styles.pane}>{children}</div>
export const Panes = ({current,multi,cls,carousel}) => {
    const [fn,name,id] = props(["fn","Name","id"],current);
    const [item_labels,label,visual,countries,unit,pane_nr,explanation] = m(useSelector,[
        p("active_labels"),path(["labels",id]),path(["fns",fn,"visual"]),p("comparison"),
        path(["units",id]),p("pane"),take_from("explanations",id)])
    const data = useSelector(createSelector(m(p,["data","comparison","fns","country"]),
        (data,sel,fns,country) => calculate(data,country,sel,fns,"visual_data")(current)));
    const country_rate = useSelector(createSelector(m(p,["response_rates","country"]),
        (rates, country) => C(r(0),path([Number(id),country]))(rates)));
    const group_avg_rate = useSelector(createSelector(m(p, ["response_rates", "comparison"]),
        (rates, comp) => C(r(0),mean, props(comp), p(id))(rates)));
    const color = useSelector(createSelector([path(["differences",current.id]),p("country"),p("colors")],
        (diff,country,colors) => C(colour(colors),p(country))(diff)));
    const dispatch = useDispatch();

    return <div className={`${styles.subindicator} 
            ${carousel !== null ? styles.carousel : ""}
            ${cls.active !== null ? styles.active : ""}`} 
        style={data ? {border:`5px solid ${color}`} : {}}
        onClick={() => {if(multi) {dispatch(selectSubindicator({select:{active:name},item:current.id}))}}}>
        <div className={styles.panes} 
            style={{transform:`translateX(${cls.active != null ? (-20*pane_nr) : 0}%)`}}>
            <Pane key={"graphpane"}>
                <Graph {...{current,visual,item_labels,data,cls,multi}} />
                <Itemlabels {...{item_labels,visual,is_active:cls.active}}/>
            </Pane>
            <Pane key={"infopane"}>
                <div className={styles.info_pane}>
                    <div>
                        <h4>Open in database:</h4>
                        <Links countries={countries} bubble={current}/>
                        <h4>Description:</h4>
                        {label.map((x,n) => <p key={`${current.id}_${n}`}>{x}</p>)}
                        <h4>Formula:</h4>
                        <p>{explanation}</p>
                        <h4>Unit:</h4>
                        <p>{unit}</p>
                    </div>
                </div>
            </Pane>
            <Pane key={"responsepane"}>
                <div className={styles.info_pane}>
                    <div>
                        <h4>Response rates:</h4>
                        <p>In 
                            <span> selected country {country_rate}% </span>
                        of participants answered this question. In comparison countries 
                            <span> on average {group_avg_rate}% </span> 
                        of participants answered this question.</p>
                    </div>
                </div>
            </Pane>
            <Pane key={"formulapane"}>
                <div className={styles.info_pane}>
                    <div>
                        <h4>Formula:</h4>
                        <img src={`/illustrations/${fn}.svg`} alt="formula illusration" />
                    </div>
                </div>
            </Pane>
            <Pane key={"linkspane"}>
                <div className={styles.info_pane}>
                    <div>
                    </div>
                </div>
            </Pane>
        </div>
    </div>
}