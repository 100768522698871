import React, { useState } from 'react';
import styles from "./topics.module.css";
import { Topic } from './topic';
import { prop as p } from 'ramda';
import { useSelector } from 'react-redux';

export function Topics({data}) {
    const [expanded,setExpanded] = useState(null);
    const underneath = useSelector(p("panel_open"));
    return <div onClick={() => setExpanded(null)} className={`${styles.topics} ${underneath ? styles.underneath : ""}`}>{
        data.map(x => (<Topic 
            expanded={expanded === x.id} 
            click={(a) => {
                a.stopPropagation(); 
                setExpanded(expanded === x.id ? null : x.id)}} 
            topic={x.Name} 
            key={x.id} 
            id={x.id}/>))}
        <div className={styles.overlay}></div>
    </div>
}