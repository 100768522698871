import React from 'react';
import {defaultTo, prop as p,propEq,toPairs} from "ramda";
import styles from './columns.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setItem } from '../../../store/load_data';
import { r } from '../../../store/transforms/math';

export const Columns = ({ data, country, item_labels, cls }) => {
    const d = p(country, data);
    const item = useSelector(p("item"));
    const dispatch = useDispatch();
    return d ? <div>
        <div style={{ width: `${d.length > 5 ? 100 : d.length * 15}%` }}
            className={styles.columns}>
            {toPairs(d).map(([key, x], n) => <div 
                style={{left: `${n * (100 / d.length)}%`,
                        width: `${100 / d.length}%`}}
                key={`col${n}`}
                onClick={x => {if(cls.active) {x.stopPropagation(); dispatch(setItem(item_labels[key]))}}}
                className={`${styles.hoverarea}  ${propEq(key,item,defaultTo([],item_labels)) ? styles.active : ""}`}>
                <div style={{
                    [Number(x) < 0 ? "borderTop" : "borderBottom"]: "0.1rem solid #2C70D4",
                    height: `${Math.abs(Number(x) * 50)}%`,
                    top: `${50 - (Number(x) > 0 ? Number(x) * 50 : 0)}%`}}
                    className={styles.column}>
                </div>
                <span style={{ top: `${44 - Number(x) * 50 - (Number(x) > 0 ? 10 : -10)}%` }}
                    className={styles.col_value}>{x === 0 ? "avg" : ((x > 0 ? "+" : "") + r(0,x*100) + "%")}</span>
            </div>)}
        </div>
    </div> : "";
};
