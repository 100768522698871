import { createSelector } from '@reduxjs/toolkit';
import { clamp, compose as C, defaultTo, isNil,path, prop as p } from 'ramda';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectIndicator } from '../../store/load_data';
import styles from "./bubble.module.css";

const NilNaN = x => isNil(x) || isNaN(x);
const colour = (colors,x) => NilNaN(x) ? "white" : colors[clamp(0,4,Math.floor(x*5))];
const icon_colour = diff => x => NilNaN(diff) && isNil(x) ? "#dedede" : "#2C70D4";

export const Bubble = ({id,name,icon,style}) => {
    const dispatch = useDispatch();
    const diff = path(["differences",id]),
        rates = path(["response_rates",id]),
        country =  p("country"),
        colors = p("colors")
    const color = useSelector(createSelector([diff,country,colors],
        (diff,country,colors) => colour(colors,p(country,diff))));
    const icon_color = useSelector(createSelector([diff,rates,country],
        (diff,rates,country) => C(icon_colour(p(country,diff)),p(country),defaultTo({}))(rates)));

    return (<div 
        className={style ? `${style} ${styles.panel}` : styles.bubble} 
        onClick={(a) => {a.stopPropagation(); dispatch(selectIndicator(id))}}>
        <div 
            className={styles[color === "white" ? "no_back" : "back"]} 
            style={{backgroundColor:color }}> 
            <div className={styles.inner}>
                <svg xmlns="http://www.w3.org/2000/svg">
                    <use xlinkHref={"#"+icon} fill={icon_color} />
                </svg>
            </div> 
        </div>
        {name ? <span className={styles.subtitle}
            style={{color : icon_color}}>{name}</span>: ""}
    </div>)
}